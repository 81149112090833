import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import PressPost from '../components/PressPost'
import SEO from '../components/SEO'

class Presse extends React.Component {

  render() {
    const { pressPosts } = this.props.data
    
    return (
      <React.Fragment>
        <SEO 
          title={`Presse`} 
          desc={`Articles parus dans la presse`}
          pathname={this.props.path} 
        />
        <Header {...this.props} />
        <MainWrapper>
          {pressPosts.edges.map((item, index) => {
            return <PressPost {...item} key={`post-${index}`} />
          })}
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Presse;
export const pageQuery = graphql`
  query PressPosts {
    pressPosts: allPrismicPresse(sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          data {
            contenu {
              html
            }
            image {
              url
            }
            image_mobile {
              url
            }
            titre {
              html
              text
            }
          }
        }
      }
    }
  }
`