import React from 'react'
import { PostContainer } from '../containers/Post'

const PressPost = ({ node: { data } }) => {
  const {
    titre,
    image,
    image_mobile,
    contenu
  } = data;

  return (
    <React.Fragment>
      <PostContainer>
        <div className="mixed">
          <div className="post-image-mobile">
            <img src={image_mobile.url} alt={titre.text} />
          </div>
          <div>
            <div className="title" dangerouslySetInnerHTML={{ __html: titre.html }}></div>
            <div 
              className="content press" 
              dangerouslySetInnerHTML={{ __html: contenu.html }}
            ></div>
          </div>
          <div className="post-image">
            <img src={image.url} alt={titre.text} />
          </div>
        </div>
      </PostContainer>
    </React.Fragment>
  )
}

export default PressPost;